window.$ = window.jQuery = require('jquery');

require('jquery-viewport-checker');
require('bootstrap');
require('leaflet');
require('slick-carousel');
require('./bootstrap-datepicker');

$(document).ready( function() {

    let startDay;

    const date = new Date();
    const currentYear = date.getFullYear();
    const currentMonth =  date.getMonth()+1;
    const currentDay = date.getDate();
    const currentDate = `${currentDay}-${currentMonth}-${currentYear}`;
    const may = `${date.getFullYear()}-06-01`;
    const november = `${date.getFullYear()}-11-01`;

    if ( new Date(currentYear, currentMonth, currentDay) >= new Date(may) && new Date(currentYear, currentMonth, currentDay) <= new Date(november) ) {
        startDay = currentDate;
        console.log(new Date(currentYear, currentMonth, currentDay));
        console.log(new Date(may));
    } else {
        startDay = `01-06-${currentYear}`;
    }

    ;(function($){
        $.fn.datepicker.dates['pl'] = {
            days: ["Niedziela", "Poniedziałek", "Wtorek", "Środa", "Czwartek", "Piątek", "Sobota"],
            daysShort: ["Niedz.", "Pon.", "Wt.", "Śr.", "Czw.", "Piąt.", "Sob."],
            daysMin: ["Ndz.", "Pn.", "Wt.", "Śr.", "Czw.", "Pt.", "Sob."],
            months: ["Styczeń", "Luty", "Marzec", "Kwiecień", "Maj", "Czerwiec", "Lipiec", "Sierpień", "Wrzesień", "Październik", "Listopad", "Grudzień"],
            monthsShort: ["Sty.", "Lut.", "Mar.", "Kwi.", "Maj", "Cze.", "Lip.", "Sie.", "Wrz.", "Paź.", "Lis.", "Gru."],
            today: "Dzisiaj",
            weekStart: 1,
            clear: "Wyczyść",
            format: "dd-mm-yyyy"
        };
    }(jQuery));

    $('.input-daterange')
    .datepicker({
        language: 'pl',
        orientation: 'auto',
        todayHighlight: true,
        autoclose: true,
        format: 'dd-mm-yyyy',
        minViewDate: 0,
        maxViewDate: 0,
        weekStart: 1,
        startDate: startDay,
        endDate: `31-10-${currentYear}`
    });   
    
    $('#dataod').each(function () {
        $(this).on('changeDate', function(e) {
            CheckIn = $(this).datepicker('getDate');
            $('#datado').datepicker('update', CheckIn).focus();					
        });				
    });  


})


